<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Incentive details <b-spinner v-if="isLoading" small type="grow" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.creator.id"
                :value="data.creator_name"
                :label="controls.creator.label"
                :readonly="controls.creator.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.name.id"
                :value="data.name"
                :label="controls.name.label"
                :readonly="controls.name.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.goal.id"
                :value="data.goal"
                :label="controls.goal.label"
                :readonly="controls.goal.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.description.id"
                :value="data.description"
                :label="controls.description.label"
                :readonly="controls.description.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.type.id"
                :ref="controls.type.id"
                :value="data.type"
                :label="controls.type.label"
                :readonly="controls.type.readonly"
                :options="controls.type.options"
                :loading="controls.type.loading"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="controls.type.required"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.account.id"
                :ref="controls.account.id"
                :value="data.account"
                :label="controls.account.label"
                :readonly="controls.account.readonly"
                :options="controls.account.options"
                :loading="controls.account.loading"
                :async="true"
                @async-search="onAccountSearch"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="controls.account.required"
              />
            </b-col>

            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.market.id"
                :ref="controls.market.id"
                :value="data.market"
                :label="controls.market.label"
                :readonly="controls.market.readonly"
                :options="controls.market.options"
                :loading="controls.market.loading"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="controls.market.required"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.cost_estimate.id"
                :value="data.cost_estimate"
                :label="controls.cost_estimate.label"
                :readonly="controls.cost_estimate.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.cost_actual.id"
                :value="data.cost_actual"
                :label="controls.cost_actual.label"
                :readonly="controls.cost_actual.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.date_start.id"
                :value-single="data.date_start"
                :label="controls.date_start.label"
                :readonly="controls.date_start.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.date_end.id"
                :value-single="data.date_end"
                :label="controls.date_end.label"
                :readonly="controls.date_end.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.date_paid.id"
                :value-single="data.date_paid"
                :label="controls.date_paid.label"
                :readonly="controls.date_paid.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.approved_by.id"
                :value="data.approved_by"
                :label="controls.approved_by.label"
                :readonly="controls.approved_by.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.date_approved.id"
                :value-single="data.date_approved"
                :label="controls.date_approved.label"
                :readonly="controls.date_approved.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.date_closed.id"
                :value-single="data.date_closed"
                :label="controls.date_closed.label"
                :readonly="controls.date_closed.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.closure_notes.id"
                :value="data.closure_notes"
                :label="controls.closure_notes.label"
                :readonly="controls.closure_notes.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.partner_contribution.id"
                :value="data.partner_contribution"
                :label="controls.partner_contribution.label"
                :readonly="controls.partner_contribution.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <h5>Related documents</h5>
          <hr />
          <files-container
            ref="files"
            :module-id="$route.meta.module.id"
            :entity-id="data.id"
            :readonly="data.reviewed === true"
            @loaded="onFileContainerLoad"
          />
        </form>
        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Incentives' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Incentive submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Incentive submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";

import InlineDatePicker from "@/components/InlineDatePicker";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import FilesContainer from "@/components/FilesContainer";

import { mapState, mapActions } from "vuex";

export default {
  name: "IncentiveSubmissionForm",
  props: {
    id: {
      type: String,
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FilesContainer,
    InlineDatePicker,
    FormSubmissionActions
  },
  data: function() {
    return {
      mode: undefined,
      isLoading: false,
      controls: {
        tabs: {
          files: {
            title: "Files"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        creator: {
          id: "input:creator",
          label: "Creator",
          changed: false,
          readonly: true
        },
        name: {
          id: "input:name",
          label: "Support name",
          changed: false,
          readonly: false
        },
        type: {
          id: "select:type",
          label: "Support type",
          options: [],
          changed: false,
          readonly: false
        },
        goal: {
          id: "input:goal",
          label: "Goal",
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Description",
          changed: false,
          readonly: false
        },
        date_start: {
          id: "datepicker:date_start",
          label: "Date start",
          changed: false,
          readonly: false
        },
        date_end: {
          id: "datepicker:date_end",
          label: "Date end",
          changed: false,
          readonly: false
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false,
          required: true,
          loading: false
        },
        cost_estimate: {
          id: "input:cost_estimate",
          label: "Cost estimate",
          changed: false,
          readonly: false
        },
        cost_actual: {
          id: "input:cost_actual",
          label: "Cost actual",
          changed: false,
          readonly: false
        },
        date_paid: {
          id: "datepicker:date_paid",
          label: "Date paid",
          changed: false,
          readonly: false,
          required: true
        },
        date_closed: {
          id: "datepicker:date_closed",
          label: "Date closed",
          changed: false,
          readonly: false,
          required: false
        },
        approved_by: {
          id: "input:approved_by",
          label: "Approved by",
          changed: false,
          readonly: true
        },
        date_approved: {
          id: "datepicker:date_approved",
          label: "Date approved",
          changed: false,
          readonly: true,
          required: false
        },
        closure_notes: {
          id: "input:closure_notes",
          label: "Closure notes",
          changed: false,
          readonly: false
        },
        partner_contribution: {
          id: "input:partner_contribution",
          label: "Partner contribution",
          changed: false,
          readonly: false
        },
        market: {
          id: "select:market",
          label: "Market",
          options: [],
          changed: false,
          readonly: false,
          required: true
        }
      },
      data: {
        id: "",
        name: "",
        goal: "",
        description: "",
        date_start: "",
        date_end: "",
        cost_estimate: "",
        cost_actual: "",
        date_paid: "",
        creator_id: "",
        creator_name: "",
        approved: false,
        approved_by_id: "",
        approved_by: "",
        date_approved: "",
        date_closed: "",
        closure_notes: "",
        partner_contribution: "",
        account: {
          id: "",
          label: ""
        },
        type: {
          id: "",
          label: ""
        },
        market: {
          id: "",
          label: ""
        }
      },
      saveInProgress: false,
      saveAndViewInProgress: false
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created: function() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },
    initialize: async function() {
      let self = this;

      this.data.id = this.id;

      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
        this.data.id = await this.$api.get("entities/nextid");
      }

      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.$api.get("dictionaries/incentives/types").then(response => {
        this.controls.type.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      this.$api.get("dictionaries/markets").then(response => {
        this.controls.market.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        self.fetchData(this.id);
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.submission_date = moment.utc().format("YYYY-MM-DD");

        this.data.creator_id = this.profile.data.id;
        this.data.creator_name =
          this.profile.data.first_name + " " + this.profile.data.last_name;
      }
    },
    onAccountSearch(payload) {
      let self = this;

      self.controls.account.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false;

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    fetchData: async function(id) {
      let self = this;

      return this.$api
        .get(`incentives/${id}`)
        .then(response => {
          if (self.$_.isEmpty(response)) return;

          self.data.type = {
            id: response["type_id"],
            label: response["Type"]
          };

          if (response["Account name"]) {
            self.data.account = {
              id: response["account_id"],
              label: response["Account name"]
            };
          }

          if (response["Market"]) {
            self.data.market = {
              id: response["market_id"],
              label: response["Market"]
            };
          }

          self.data.id = self.id;
          self.data.name = response["Support name"];

          self.data.goal = response["Goal"];
          self.data.description = response["Description"];
          self.data.date_start = response["Date start"];
          self.data.date_end = response["Date end"];
          self.data.cost_estimate = response["Cost estimate"];
          self.data.cost_actual = response["Cost actual"];
          self.data.date_paid = response["Date paid"];
          self.data.creator_id = response["creator_id"];
          self.data.creator = response["Created by"];
          self.data.approved_by_id = response["approved_by_id"];
          self.data.approved_by = response["Approved by"];
          self.data.date_approved = response["Date approved"];
          self.data.date_closed = response["Date closed"];
          self.data.closure_notes = response["Closure notes"];
          self.data.partner_contribution = response["Partner contribution"];

          self.data.approved = response["Approved"] === "Yes";

          if (self.data.approved) {
            // let setReadonly = self.$form.updateControlsState.bind(this)
            // setReadonly(true)
          }
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);
        });
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let self = this;
      let method = "put";
      let url = `incentives/${self.data.id}`;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = "incentives";
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;

          this.$form.makeToastInfo(response.message);

          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;

          let msg = error.message;

          if (!msg) msg = "Error occured. Please reload page!";

          this.$form.makeToastError(msg);

          return Promise.resolve(false);
        });
    },
    cancel() {
      this.$router.back();
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(_field, _value, _mode) {
      let payload = {
        id: this.id,
        self: this,
        url: "incentives",
        field: _field,
        value: _value,
        mode: _mode
      };

      this.$form.updateField2(payload);
    },
    updateSelect(id, value) {
      this.updateField(id, value);
    }
  },
  watch: {}
};
</script>

<style></style>
